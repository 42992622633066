import { useState, useRef } from 'react'
import classNames from 'classnames'
import fallbackImage from '~/public/images/fallback.png'
import { Image } from '../Image'
import { ImageProps } from '../Image/Image.types'
import { ProductImageModal } from '../ProductImageModal'
import styles from './ProductImage.module.css'

const DISTANCE_MULTIPLIER = 1.2

export const ProductImage = (props: ImageProps) => {
  const { src, alt, ...rest } = props
  const [isModalOpen, setModalOpen] = useState(false)
  const touchStartDistance = useRef<number | null>(null)

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 2) {
      const touch1 = e.touches.item(0)
      const touch2 = e.touches.item(1)
      if (touch1 && touch2) {
        const distance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        )
        touchStartDistance.current = distance
      }
    }
  }

  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    if (e.touches.length === 2 && touchStartDistance.current !== null) {
      const touch1 = e.touches.item(0)
      const touch2 = e.touches.item(1)
      if (touch1 && touch2) {
        const newDistance = Math.hypot(
          touch2.clientX - touch1.clientX,
          touch2.clientY - touch1.clientY
        )

        if (newDistance > touchStartDistance.current * DISTANCE_MULTIPLIER) {
          setModalOpen(true)
          touchStartDistance.current = null
        }
      }
    }
  }

  return (
    <>
      <div
        className='relative'
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}>
        <Image
          src={src || fallbackImage?.src}
          alt={alt}
          {...rest}
          onClick={() => {
            return setModalOpen(true)
          }}
          className={classNames(props.className, styles.root, 'cursor-zoom-in')}
        />
      </div>

      <ProductImageModal
        isOpen={isModalOpen}
        onClose={() => {
          return setModalOpen(false)
        }}
        src={String(src) || fallbackImage?.src}
        alt={alt}
      />
    </>
  )
}
