import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { useMedia } from 'react-use'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { IconX } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from '~/components/Button'
import { Image } from '../Image'
import styles from './ProductImageModal.module.css'

interface ImageModalProps {
  isOpen: boolean
  onClose: () => void
  src: string
  alt: string
}

export const ProductImageModal = ({
  isOpen,
  onClose,
  src,
  alt,
}: ImageModalProps) => {
  const isTabletScreen = useMedia('(max-width: 767px)', false)
  const [cursorGrabbing, setCursorGrabbing] = useState<boolean>(false)
  const overlayRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      overlayRef.current?.focus()
    }
  }, [isOpen])

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === e.currentTarget) {
      onClose()
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Escape') {
      onClose()
    }
  }

  if (!isOpen) {
    return null
  }

  return (
    <>
      {createPortal(
        <div
          className={classNames(styles.modalOverlay, 'fixed inset-0')}
          role='dialog'
          aria-modal='true'
          tabIndex={-1}>
          <div
            ref={overlayRef}
            role='button'
            tabIndex={0}
            className='absolute inset-0'
            onClick={handleOverlayClick}
            onKeyDown={handleKeyDown}
          />
          <div>
            <div className='relative'>
              <Button
                variant='ghost'
                className={classNames(
                  styles.closeButton,
                  'absolute top-2 right-2 md:top-4 md:right-4'
                )}
                onClick={onClose}>
                <IconX
                  size={24}
                  style={{ color: 'var(--color-text-primary-blue)' }}
                />
              </Button>
            </div>

            <TransformWrapper
              initialScale={isTabletScreen ? 2 : 1}
              onPanningStart={() => {
                return setCursorGrabbing(true)
              }}
              onPanningStop={() => {
                return setCursorGrabbing(false)
              }}>
              <TransformComponent
                wrapperClass={classNames(styles.modalWrapper, 'bg-white')}>
                <Image
                  src={src}
                  alt={alt}
                  className={classNames(
                    styles.modalImage,
                    cursorGrabbing && styles.grabbing,
                    'md:w-full md:h-screen'
                  )}
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>,
        document.body
      )}
    </>
  )
}
